import React, { useContext, useState, useEffect } from 'react';
import LogoOW from '@assets/images/logoEvaluator.png';
import Flags from '@components/Flags';
import { AuthContext } from '@context/auth/AuthContext';
import { useForm } from '@hooks/useForm';
import { styled, Button, Grid, TextField, Typography, Tab, Tabs } from '@mui/material';
import { useCommonStyles } from '@styles/common.style'
import { useStyles } from '@styles/evaluator.style'
import { useTranslation } from 'react-i18next';
import serviceEvaluatorData from '@services/serviceEvaluatorData'
import { snackActions } from '@helpers/snackbarUtils';

export function a11yProps (index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      key: index
    }
}

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none'
}));

const SignInEvaluator = ({ flagOptions }) => {
    const { t, i18n } = useTranslation();
    const classes = useCommonStyles()
    const classes2 = useStyles()
    const { login } = useContext(AuthContext);
    const [value, setValue] = useState(0)
    const [code, setCode] = useState('')
    const [ isFirstTimeAsBoss, setIsFirstTimeAsBoss ] = useState(true)

    const { formData, loginPassword, loginEmail, handleChange } = useForm({
        loginEmail: '',
        loginPassword: '',
        loginMode: 'LoginEvaluator'
    });

    const handleChangeLanguage = e => {
        const newLanguage = e.currentTarget.dataset.lang;   
        const tabValue = e.currentTarget.dataset.value;
        sessionStorage.setItem('lang', newLanguage);

        setValue(parseInt(tabValue));
        i18n.changeLanguage(newLanguage);
    };

    const fetchCodeExist = async (code, e) => {
        const { data, error } = await serviceEvaluatorData.getEvaluatorParticipantFromCode(code);
        if (!error) {
            if (data.isActive) {
                if ((!data.isParticipant && (data.totalReceiverResponses < data.numReceivers)) || (data.isParticipant && !data.isAutoevalDone)) {
                    handleSubmit(e)
                } else if (data.isParticipant && data.isAutoevalDone) {
                    snackActions.warning(
                        <>
                          <Grid container spacing={1} sx={{width: '50vw', minWidth: '280px'}}>
                            <Grid item xs={12} ml={2}>
                                <Typography variant="h6" textAlign={'left'}>
                                    {t('EVALUATOR.NO_FIRST_B1')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}  ml={2}>
                                <Typography variant="body1" textAlign={'left'}>
                                    {t('EVALUATOR.NO_FIRST_B2')}
                                </Typography>
                            </Grid>
                          </Grid>
                        </>
                      );
                } else {
                    snackActions.error(t('EVALUATOR.CODE_ERROR_COL'));
                }
            } else {
                snackActions.error(t('EVALUATOR.CODE_ERROR'));
            }
        }
    };
    
    const handleSubmit = e => {
        e.preventDefault();
        login(formData);  
    };

    useEffect(() => {
        setCode(formData.surveyCode)
    }, [formData])
    

    return (
        <form onSubmit={handleSubmit} className={classes2.siginEvaluator}>
            <Grid display={'flex'} justifyContent={'center'} mb={-6}>
                <img src={LogoOW} alt='logo' className={classes2.image}></img>
            </Grid>
            <Grid mb={-5} px={0}>
                <Typography className={classes2.title}>{t('EVALUATOR.AUTOEVALUATION')}</Typography>
            </Grid>
            <Grid mb={-2}>
                <Tabs
                    value={value}
                    onChange={e => handleChangeLanguage(e)}
                    aria-label='basic tabs example'
                    
                >
                {flagOptions.map(({ languageCode, Icon }, i) => {    
                    const languageTitle = i == 0 ? 'Español' : i == 1 ? 'English' : 'Italiano';
                    return (
                    <AntTab 
                        key={i}
                        data-lang={languageCode} 
                        data-value={i} 
                        label={
                            <>
                                <Typography variant='subtitle1' sx={{fontSize:'1.1em'}} >{languageTitle}</Typography><Icon style={{ display: 'flex' }}/>
                            </> 
                        } 
                        {...a11yProps(`flagTab${i}`)} 
                    />
                    )
                })}
                </Tabs>
            </Grid>
            <Grid mb={-2} item md={12} xs={12} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Typography mb={3} style={{ fontSize: '1.2rem' }}>{t('EVALUATOR.CODE')}:</Typography>
                <TextField
                    label={t('LABEL.CODE')}
                    name='surveyCode'
                    size='small'
                    onChange={handleChange}
                    inputProps={{ maxLength: 8, style: { fontSize: '1.2rem' } }}
                    InputLabelProps={{ style: { fontSize: '1.2rem' } }}
                    sx={{textAlign: 'center', maxWidth:'50%', minWidth: '130px'}}
                />
            </Grid>
            <Grid mb={15}>
                <Button 
                  onClick={(e) => fetchCodeExist(code ,e)} 
                  variant='outlined' 
                  size='large' 
                  style={{ fontWeight: 'bold' }}
                >
                  {t('EVALUATOR.ACCESS')}
                </Button>

            </Grid>
        </form>
    );
};

export default SignInEvaluator;