import React, { useEffect, useState, useRef, useContext } from 'react';
import { useStyles } from '@styles/participants.style';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Checkbox,
  Button,
  Box,
  TextField,
  Typography
} from '@mui/material';
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom';
import getCurrentJourney from '@helpers/currentJourney';
import serviceParticipantsTargets from '@services/serviceParticipantsTargets';
import serviceCycleJourneysParticipants from '@services/serviceCycleJourneysParticipants';
import Loading from '@components/Loading';
import Logo from '@assets/images/logoCampus.png';
import { snackActions } from '@helpers/snackbarUtils';
import serviceCycleJourneys from '@services/serviceCycleJourneys';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { AuthContext } from '@context/auth/AuthContext';

const Targets = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    cycle: [cycle, setCycle],
    displaySaw: [displaySaw, setDisplaySaw]
  } = useOutletContext();
  const [displayPrep, setDisplayPrep] = useState('');
  const [nextTargets, setNextTargets] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [checkFree, setCheckFree] = useState(false);
  const [targetTexts, setTargetTexts] = useState({});
  const inputRef = useRef({});
  const [isFirstTimeSelection, setIsFirstTimeSelection] = useState(true);
  const [targetsOn, setTargetsOn] = useState(true);
  const { user } = useContext(AuthContext);
  const lang = sessionStorage.getItem('lang');
  const idLang = lang === 'es' ? 1 : lang === 'en' ? 2 : lang === 'it' ? 3 : 1;
  const [hasPreviousTargets, setHasPreviousTargets] = useState(false);
  const [idJourney, setIdJourney] = useState(null);
  const [journey, setJourney] = useState(null);
  const currentJourneyIndex = cycle.cycleJourneys.indexOf(journey);
  const isLastJourney = currentJourneyIndex === cycle.cycleJourneys.length - 1;
  const [participantDetails, setParticipantDetails] = useState({});
  const [originalTargets, setOriginalTargets] = useState([]);

  useEffect(() => {
    const journey = getCurrentJourney(cycle);
    //NOTA IMPORTANTE: TENEMOS QUE HACER ESTO PORQUE SEGÚN POR DONDE ENTRA DEVUELVE UNA ESTRUCTURA DE DATOS U OTRA Y CAMBIAR TODA LA ESTRUCTURA EN BACK NO ES SENCILLO
    let tmpJourneyParticipants = journey.cycleJourneysParticipants;
    if (tmpJourneyParticipants == null || tmpJourneyParticipants == undefined) {
        tmpJourneyParticipants = journey.cycleJourneyParticipants;
    }

    const cycleJourneysParticipant = tmpJourneyParticipants.find(
      cycleJourneyParticipant => cycleJourneyParticipant.idParticipant == user.idParticipant
    );
    console.log(cycleJourneysParticipant);

    if (cycleJourneysParticipant?.cycleJourneyAssistance) {
      fetchNextTargets({
        idCycleJourney: journey.idCycleJourney,
        idParticipant: user.idParticipant,
        idLanguage: idLang
      });
      setIdJourney(journey.idCycleJourney);
      setJourney(journey);
    } else {
      checkOtherJourney(journey);
    }
  }, []);

  useEffect(() => {
    if (journey && idJourney) {
      serviceCycleJourneys
        .getCycleJourneyDetails(idJourney)
        .then(response => {
          if (!response.error) {
            setTargetsOn(response.data.cycleTarget);
          }
        });
  
      serviceCycleJourneysParticipants
        .cycleJourneParticipantDetailsByJourney(idJourney)
        .then(response => {
          if (!response.error && response.data) {
            setParticipantDetails(response.data);
          }
        });
  
      if (cycle.cycleJourneys.length === cycle.cycleJourneys.indexOf(journey) + 1) {
        setDisplayPrep('none');
      }
    }
  }, [journey, idJourney]);

  useEffect(() => {
    if (hasPreviousTargets) {
      setDisplayPrep('block');
    } else {
      setDisplayPrep('none');
    }
  }, [hasPreviousTargets]);

  useEffect(() => {
    if ((!targetsOn || selectedTargets.length >= journey?.cycleTargetMinNum) && hasPreviousTargets) {
      setDisplaySaw('block');
    } else {
      setDisplaySaw('none');
    }
  }, [targetsOn, selectedTargets]);

  useEffect(() => {
    const initialTargetTexts = { ...targetTexts };
    selectedTargets.forEach(target => {
      if (!initialTargetTexts[target.idCycleJourneysTarget]) {
        initialTargetTexts[target.idCycleJourneysTarget] = target.targetText || '';
      }
    });
    setTargetTexts(initialTargetTexts);
  }, [selectedTargets]);

  const checkOtherJourney = async (journey) => {
    const { data } = await serviceCycleJourneysParticipants.checkParatrooper(user.idParticipant, journey.idCycleJourney);
    if (data.idCycleJourney != 0) {
      setIdJourney(data.idCycleJourney);
      setJourney(data);
      fetchNextTargets({
        idCycleJourney: data.idCycleJourney,
        idParticipant: user.idParticipant,
        idLanguage: idLang
      });
    } else {
      const previousAssistedJourney = await findAssistedJourney(journey);
      if (previousAssistedJourney?.idCycleJourney != 0) {
        setIdJourney(previousAssistedJourney.idCycleJourney);
        setJourney(previousAssistedJourney);
        fetchNextTargets({
          idCycleJourney: previousAssistedJourney.idCycleJourney,
          idParticipant: user.idParticipant,
          idLanguage: idLang
        });
      } else {
        // TODO ver que hacer si no hay ninguna jornada a la que asista
      }
    }
  };

  const fetchNextTargets = async (dataTargets) => {
    const { data, error } = await serviceParticipantsTargets.getListByJourneyAndParticipant(dataTargets);
    if (!error) {
      let tmpSelectedTargets = [];
      let tmpSelectedParents = data.filter(target => target.existsInParticipants === 1);

      // Recorrer todos los tmpSelectedParents
      for (const parent of tmpSelectedParents) {
        // Si el parent no tiene hijos, agregarlo tal cual a tmpSelectedTargets
        if (parent.childs !== null && parent.childs.length === 0) {
          tmpSelectedTargets.push(parent);
        } else {
          // Si el parent tiene hijos, agregarlos a tmpSelectedTargets
          tmpSelectedTargets.push(...parent.childs);
        }
      }
      if (tmpSelectedTargets.length > 0) {
        setHasPreviousTargets(true);
      } else {
        setHasPreviousTargets(false);
      }
  
      // Guardar los targets originales cuando se cargan
      setOriginalTargets(tmpSelectedTargets);
        
      setSelectedTargets(tmpSelectedTargets);
      setLoading(false);
      setNextTargets(data);
    }
  };

  const haveTargetsChanged = () => {
    if (originalTargets.length !== selectedTargets.length) return true;
  
    return selectedTargets.some((target, index) => {
      return (
        target.idCycleJourneysTarget !== originalTargets[index].idCycleJourneysTarget ||
        target.targetText !== originalTargets[index].targetText
      );
    });
  };
  
  

  const postTargets = async () => {
    // Actualizar selectedTargets con los valores de targetTexts antes de guardar
    const updatedTargets = selectedTargets.map(target => ({
      ...target,
      targetText: targetTexts[target.idCycleJourneysTarget] || target.targetText
    }));

    var data = {
      idCycleJourney: idJourney,
      idParticipant: user.idParticipant,
      idTargets: updatedTargets.map(target => ({
        idCycleJourneysTarget: target.idCycleJourneysTarget,
        idTarget: target.idTarget,
        targetText: target.targetText
      }))
    };

    if (updatedTargets.length < journey.cycleTargetMinNum) {
      snackActions.error(t('PARTICIPANTS.MIN_TARGETS') + ' ' + journey.cycleTargetMinNum);
      return false;
    } else {
      const response = await serviceParticipantsTargets.postUpdateJourneyTargets(data);
      if (!response.error) {
        setDisplaySaw('block');
        if (cycle.cycleJourneys.length !== cycle.cycleJourneys.indexOf(journey) + 1) {
          setDisplayPrep('block');
        }
        if (isFirstTimeSelection && journey.activeAssesment) {
          setIsFirstTimeSelection(false);
        }
        return true;
      } else {
        return false;
      }
    }
  };

  const goToActiveJourney = () => {
    navigate('/activeJourney', {
      state: { journey, idJourney }
    });
  };

  const goToScoreJourney = () => {
    navigate('/scoreJourney', {
      state: { journey, idJourney }
    });
  };

  const handleContinue = async () => {
    const saved = await postTargets();
    if (saved) {
        const response = await serviceCycleJourneysParticipants.cycleJourneParticipantDetailsByJourney(idJourney);
        if (!response.error && response.data) {
            const { cycleJourneyParticipantRemarks, cycleJourneyParticipantRate } = response.data;

            if (isLastJourney) {
                if (cycleJourneyParticipantRemarks && cycleJourneyParticipantRate) {
                    // Si es la última jornada y ya se han dado comentarios y calificación, no se navega a ningún sitio.
                    return;
                } else if (journey.activeAssesment) {
                    // Si es la última jornada y la valoración está activa pero no se ha completado, navegar a scoreJourney.
                    navigate("/scoreJourney", {
                        state: { journey, idJourney }
                    });
                }
            } else {
                // Si no es la última jornada y la valoración está activa y no se ha completado, redirigir a scoreJourney
                if (journey.activeAssesment && !(cycleJourneyParticipantRemarks && cycleJourneyParticipantRate)) {
                    navigate("/scoreJourney", {
                        state: { journey, idJourney }
                    });
                } else if (journey.idPreparation !== 0) {
                    // Si idPreparation es diferente de 0, redirigir a activeJourney
                    navigate("/activeJourney", {
                        state: { journey, idJourney }
                    });
                }
            }
        }
    }
  };

  const findAssistedJourney = async (currentJourney) => {
    const currentJourneyIndex = cycle.cycleJourneys.indexOf(currentJourney);
    let previousJourneyIndex = currentJourneyIndex - 1;

    while (previousJourneyIndex >= 0) {
      const previousJourney = cycle.cycleJourneys[previousJourneyIndex];
      const participant = previousJourney.cycleJourneyParticipants.find(
        cycleJourneyParticipant => cycleJourneyParticipant.idParticipant == user.idParticipant
      );

      if (participant && participant.cycleJourneyAssistance) {
        return previousJourney;
      } else {
        const { data } = await serviceCycleJourneysParticipants.checkParatrooper(user.idParticipant, previousJourney.idCycleJourney);
        if (data?.idCycleJourney != 0) {
          return data;
        }
      }
      previousJourneyIndex--;
    }
    return null;
  };

  const handleTargets = (e, idCycleJourneysTarget, idTarget) => {
    const isChecked = e.target.checked;
    let tmpSelectedTargets = [...selectedTargets];
    let pos = tmpSelectedTargets.findIndex(
      x => x.idCycleJourneysTarget === idCycleJourneysTarget && x.idTarget === idTarget
    );

    if (pos > -1 && !isChecked) {
      tmpSelectedTargets.splice(pos, 1);
    } else {
      tmpSelectedTargets.push({
        idCycleJourneysTarget: idCycleJourneysTarget,
        idTarget: idTarget,
        targetText: targetTexts[idCycleJourneysTarget] || ''
      });
    }
    setSelectedTargets(tmpSelectedTargets);
  };

  const checkList = (targetId, idCycleJourneysTarget) => {
    if (selectedTargets.length > 0) {
      const index = selectedTargets.findIndex(target => target.idTarget === targetId && target.idCycleJourneysTarget === idCycleJourneysTarget);
      return index !== -1;
    }
    return false;
  };

  const handleTargetText = (idCycleJourneysTarget, idTarget, text) => {
    setTargetTexts(prevTexts => ({
      ...prevTexts,
      [idCycleJourneysTarget]: text
    }));

    let tmpSelectedTargets = [...selectedTargets];
    let pos = tmpSelectedTargets.findIndex(
      x => x.idCycleJourneysTarget === idCycleJourneysTarget
    );

    if (pos === -1 ) {
      tmpSelectedTargets.push({
        idCycleJourneysTarget: idCycleJourneysTarget,
        idTarget: idTarget,
        targetText: targetTexts[idCycleJourneysTarget] || ''
      });
      setSelectedTargets(tmpSelectedTargets);
    }

    if (text.length === 0) {
      tmpSelectedTargets.splice(pos, 1);
      setSelectedTargets(tmpSelectedTargets);
    }
  };

  const getFreeName = idCycleJourneysTarget => {
    return targetTexts[idCycleJourneysTarget] || '';
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container spacing={0} marginTop={1}>
        <Grid item xs={9.5} pt={1}>
          <Typography className={classes.subtitle}>
             {isLastJourney ? t('PARTICIPANTS.MY_LAST_TARGETS') : t('PARTICIPANTS.MY_TARGETS')}
          </Typography>
        </Grid>
        <Grid item xs={2} paddingLeft={2} paddingTop={0}>
          <Box sx={{height: '100%', width: '100%', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img className={classes.image} src={Logo} alt='logoxs' />
          </Box>
        </Grid>
      </Grid>
      <br />
      {!targetsOn && (
        <Grid padding={2} marginY={9} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          {/* 
          No quieren que salga ningún texto si no están los objetivos activados t botón quitado
          <InfoOutlinedIcon className={classes.colorPrimary} style={{ height: '2em', width: '2em', marginLeft: '1em', marginRight: '1em' }} />
          
          <Typography className={classes.subtitle}>
            {t('PARTICIPANTS.NO_TARGETS')}
          </Typography>
          */}
        </Grid>
      )}
      {targetsOn && (
        <Grid container spacing={1} padding={1}>
          {cycle &&
            nextTargets?.length > 0 &&
            nextTargets?.map((target, i) => {
              return (
                <Grid
                  item
                  container
                  spacing={1}
                  className={classes.targets}
                  key={'trg-' + target.idCycleJourneysTarget + i}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Grid item xs={target.childs === null ? 12 : 10.5} className={classes.padding0} color={'grey'} fontSize={'1.1em'}>
                    {!target.isFreeText && (
                      <div style={{ color: target.childs === null || target.childs.length === 0 ? '#0085B4' : 'inherit' }}>
                        {target.targetLocaleName.toUpperCase()}
                      </div>
                    )}
                    {target.isFreeText && (
                      <TextField
                        id={target.idCycleJourneysTarget.toString()}
                        fullWidth
                        inputRef={el => {
                          if (inputRef.current) {
                            inputRef.current[target.idCycleJourneysTarget] = el;
                          }
                        }}
                        label={target.targetLocaleName}
                        name={'targetText' + target.idCycleJourneysTarget}
                        value={getFreeName(target.idCycleJourneysTarget)}
                        onChange={e => handleTargetText(target.idCycleJourneysTarget, target.idTarget, e.target.value)}
                        size='small'
                        inputProps={{ maxLength: 100 }}
                      />
                    )}
                  </Grid>
                  <Grid
                    item xs={1.5}
                    className={classes.padding0}
                    sx={{
                      display: target.childs === null || target.childs.length === 0 ? 'flex' : 'none',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Checkbox
                      className={classes.checkbox}
                      name={target.name}
                      disableRipple
                      checked={checkList(target.idTarget, target.idCycleJourneysTarget)}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 48, color: '#0085B4', strokeWidth: '1 !important' } }}
                      onChange={e =>
                        handleTargets(
                          e,
                          target.idCycleJourneysTarget,
                          target.idTarget
                        )
                      }
                    />
                  </Grid>
                  {target.childs.map((child, i) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        container
                        spacing={0}
                        key={'thg-' + target.idTarget + '-' + child.idCycleJourneysTarget}
                        mt={1}
                        p={0}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <Grid item xs={1}></Grid>
                        <Grid item xs={9} className={classes.padding0} color={'grey'} fontSize={'1.1em'}>
                          {!child.isFreeText && (
                            <div style={{ color: '#0085B4' }}>{child.targetLocaleName.toUpperCase()}</div>
                          )}
                          {child.isFreeText && (
                            <TextField
                              id={child.idCycleJourneysTarget.toString()}
                              fullWidth
                              inputRef={el => {
                                if (inputRef.current) {
                                  inputRef.current[child.idCycleJourneysTarget] = el;
                                }
                              }}
                              label={child.targetLocaleName}
                              name={'targetText' + child.idCycleJourneysTarget}
                              value={getFreeName(child.idCycleJourneysTarget)}
                              onChange={e => handleTargetText(child.idCycleJourneysTarget, child.idTarget, e.target.value)}
                              size='small'
                              inputProps={{ maxLength: 100 }}
                            />
                          )}
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          className={classes.padding0}
                          ml={child.targetIdParent !== 99 ? 1 : 0}
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <Checkbox
                            className={classes.checkbox}
                            name={child.name}
                            disableRipple
                            checked={checkList(child.idTarget, child.idCycleJourneysTarget)}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 48, color: '#0085B4', strokeWidth: '1 !important' } }}
                            onChange={e =>
                              handleTargets(
                                e,
                                child.idCycleJourneysTarget,
                                child.idTarget
                              )
                            }
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            })}
        </Grid>
      )}
      {targetsOn && (
        <Grid
          marginTop={3}
          marginBottom={6}
          marginRight={2}
          display='flex'
          justifyContent='end'
        >
          <Button
            variant='contained'
            size='large'
            disabled={
              selectedTargets.length < journey.cycleTargetMinNum || 
              (!haveTargetsChanged() && !checkFree && 
                !(journey.activeAssesment && 
                (!participantDetails.cycleJourneyParticipantRemarks || !participantDetails.cycleJourneyParticipantRate)))
            }
            onClick={handleContinue}
          >
            {(hasPreviousTargets && !(journey.activeAssesment && !participantDetails.cycleJourneyParticipantRemarks && !participantDetails.cycleJourneyParticipantRate))
              ? t('BUTTON.MODIFY')
              : t('BUTTON.SAVE_TARGETS')}
          </Button>
        </Grid>
      )}

      {/* No sale el botón de "Ver preparación..." cuando no la hay. */}
      {
        journey.idPreparation !== 0 && journey.idPreparation !== null && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15vh' }}>
          <Grid
            item
            xs={10}
            marginTop={0}
            marginBottom={10}
            display={displayPrep}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Button
              className={
                (!hasPreviousTargets && !checkFree) || 
                (journey.activeAssesment && (!participantDetails.cycleJourneyParticipantRemarks || !participantDetails.cycleJourneyParticipantRate))
                ? classes.btnSecondaryDisabled 
                : classes.btnSecondary
              }
              variant='contained'
              size='large'
              disabled={
                (!hasPreviousTargets && !checkFree) || 
                (journey.activeAssesment && (!participantDetails.cycleJourneyParticipantRemarks || !participantDetails.cycleJourneyParticipantRate))
              }
              onClick={goToActiveJourney}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ fontSize: '1.5em', fontWeight: 'bold' }}>
                  {t('PARTICIPANTS.BTNPREPARATION_LINE1')}
                </span>
                <span style={{ fontSize: '1em' }}>
                  {t('PARTICIPANTS.BTNPREPARATION_LINE2')}
                </span>
              </div>
            </Button>
          </Grid>
        </div>
        )
      }
    </>
  );
};

export default Targets;
